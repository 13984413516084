import * as React from "react";
import { useRecordContext } from 'react-admin';
import { Link } from 'react-router-dom';

const OnlineSalesCountField = (props) => {
    const record = useRecordContext(props);

    const url = new URL( record.source_url );

    const onlineSalesLink = `/donations?filter={"q":"${url.search}"}`;
    return ( <Link to={onlineSalesLink}>{record.submission_count}</Link> );
}

OnlineSalesCountField.defaultProps = { label: 'Online Sales' };

export default OnlineSalesCountField;
