import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

const DownloadAutoresponseHTMLButton = ( props ) => {

    const handleClick = (e) => {
        e.preventDefault( );
        
        var submission_id = props.record.submission_id;

        if ( 'undefined' === typeof submission_id )
            submission_id = props.record.id;

        window.open( window.__rest_uri+'/v2-download-email-autoresponse/'+submission_id+'?authToken='+localStorage.getItem( 'authToken' ), '_blank' );
    };

    return (
        <React.Fragment>
            <Button variant="contained" color="secondary" onClick={handleClick}>HTML</Button>
        </React.Fragment>
    );
}

DownloadAutoresponseHTMLButton.propTypes = {
    record: PropTypes.object
};

export default connect(null, { })(DownloadAutoresponseHTMLButton);
