import * as React from "react";

import { Edit, 
    SimpleForm,
    TextInput,
    SelectInput,
    required } from 'react-admin';

import { Typography, Box } from '@material-ui/core';

const Title = ({ record }) => {
    return <span>{record && record.first_name ? `Editing ${record.first_name}` : 'New Person'}</span>;
};

export const PersonEditor = (props) => (
    <Edit title={<Title />} {...props}>
        <SimpleForm>
            <Typography variant="h6" fullWidth>Editing Person</Typography>
            <TextInput label="ID" source="id" fullWidth disabled />
            <TextInput label="Hash" source="hash" fullWidth disabled />
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput source="first_name" fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="middle_name" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="last_name" fullWidth validate={required()} />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput source="postal_code" fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="email_address" fullWidth validate={required()} />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="phone_number" fullWidth />
                </Box>
            </Box>
            <Typography variant="h6" fullWidth>Additional Details</Typography>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Address Line 1" source="attributes.address_line1" fullWidth />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="Address Line 2" source="attributes.address_line2" fullWidth />
                </Box>
            </Box>
            <Box display="flex" fullWidth>
                <Box flex={1} mr="0.5em">
                    <TextInput label="City" source="attributes.address_city" fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <SelectInput label="Province" source="attributes.address_province" choices={[
                        { id: 'AB', name: 'Alberta' },
                        { id: 'BC', name: 'British Columbia' },
                        { id: 'MB', name: 'Manitoba' },
                        { id: 'NB', name: 'New Brunswick' },
                        { id: 'NL', name: 'Newfoundland and Labrador' },
                        { id: 'NS', name: 'Nova Scotia' },
                        { id: 'ON', name: 'Ontario' },
                        { id: 'PE', name: 'Prince Edward Island' },
                        { id: 'QC', name: 'Quebec' },
                        { id: 'SK', name: 'Saskatchewan' },
                        { id: 'NT', name: 'Northwest Territories' },
                        { id: 'NU', name: 'Nunavut' },
                        { id: 'YT', name: 'Yukon' },
                    ]} fullWidth />
                </Box>
                <Box flex={1} mr="0.5em">
                    <TextInput source="postal_code" fullWidth />
                </Box>
            </Box>
        </SimpleForm>
    </Edit>
);

export default PersonEditor;
