import * as React from "react";

import {
    List, Datagrid,
    TextField, NumberField, BooleanField, FunctionField, DateField,
    ReferenceInput, TextInput, AutocompleteInput, SelectInput, DateInput
} from 'react-admin';

import { useState, useEffect } from 'react';
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Bar, BarChart, PieChart, Pie, Tooltip, Cell } from 'recharts';

import renderPhoneNumber from './Renderers/renderPhoneNumber';

import SendAutoresponseButton from './SendAutoresponseButton';
import SendAutoresponsePreviewButton from './SendAutoresponsePreviewButton';

import DownloadAutoresponseHTMLButton from './DownloadAutoresponseHTMLButton';
import DownloadAutoresponsePDFButton from './DownloadAutoresponsePDFButton';

/* charts */

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const DonationsPieChart = (props) => {
    const [data, setData] = useState();

    useEffect(() => {
        const route = decodeURIComponent( props.location.search.replace( '?', '' ) );
        const query = new URLSearchParams( route );

        const filter = JSON.parse( query.get( 'filter' ) );
        const filterString = ( new URLSearchParams( filter ) ).toString( );

        var restRoute = window.__rest_uri+'/v2/fundraising?_dim=transaction_type';
        if ( null !== filter && 0 < Object.keys( filter ).length && 0 < filterString.length )
            restRoute += '&'+filterString;

        console.log( restRoute, filterString );

        fetch(
            restRoute,
            { headers: { 'Content-Type': 'application/json', 'authorization': 'Basic ' + localStorage.getItem('authToken') } }
        )
            .then((res) => res.json())
            .then((data) => setData(data));
    }, [ props ]);

    if (data === undefined)
        return <>Fetching donations data...</>;

    return (
        <ResponsiveContainer width="38%" height={350}>
            <PieChart margin={{ top: 20, right: 20, left: 20, bottom: 20}}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx={200}
                    cy={150}
                    outerRadius={120}
                    fill="#8884d8"
                    label
                >
                    {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    )
}

const DonationsBarChart = (props) => {
    const [data, setData] = useState();

    useEffect(() => {
        const route = decodeURIComponent( props.location.search.replace( '?', '' ) );
        const query = new URLSearchParams( route );

        const filter = JSON.parse( query.get( 'filter' ) );
        const filterString = ( new URLSearchParams( filter ) ).toString( );

        var restRoute = window.__rest_uri+'/v2/fundraising?_dim=month';
        if ( null !== filter && 0 < Object.keys( filter ).length && 0 < filterString.length )
            restRoute += '&'+filterString;

        fetch(
            restRoute,
            { headers: { 'Content-Type': 'application/json', 'authorization': 'Basic ' + localStorage.getItem('authToken') } }
        )
            .then((res) => res.json())
            .then((data) => setData(data));
    }, [ props ]);

    if (data === undefined)
        return <>Fetching donations data...</>;

    return (
        <ResponsiveContainer width="60%" height={350}>
            <BarChart margin={{ top: 20, right: 20, left: 20, bottom: 20}} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Bar dataKey="value" fill="#8884d8"> 
              {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
              </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

/* actual donations list */
const filters = [
    <TextInput label="Search URL" source="q" alwaysOn />,
    <ReferenceInput fullWidth label="Posts" source="post_id" reference="posts">
        <AutocompleteInput optionText="title" />
    </ReferenceInput>,
    <TextInput fullWidth label="Source URL" source="source_url" />,
    <ReferenceInput fullWidth label="Merchant" source="merchant_id" reference="merchants">
        <AutocompleteInput optionText="label" />
    </ReferenceInput>,
    <TextInput fullWidth label="Charge ID" source="merchant_charge_label" />,
    <SelectInput label="Card Type" source="card_type" choices={[
        { id: 'V', name: 'Visa' },
        { id: 'M', name: 'MasterCard' },
        { id: 'A', name: 'American Express' },
    ]} />,
    <TextInput fullWidth label="Card Last 4" source="card_last4" />,
    <ReferenceInput fullWidth label="Fund Code" source="fund_code" reference="fundcodes">
        <AutocompleteInput optionText="fund_code" optionValue="fund_code" />
    </ReferenceInput>,
    <TextInput fullWidth label="Email" source="email" />,
    <DateInput fullWidth label="Start Date" source="start_donation_datetime" />,
    <DateInput fullWidth label="End Date" source="end_donation_datetime" />,
];

export const DonationsList = (props) => (
    <div>
        <div className="flex">
            <DonationsBarChart {...props} />
            <DonationsPieChart {...props} />
        </div>
        <List {...props} filters={filters} sort={{ field: 'donation_datetime', order: 'DESC' }} bulkActionButtons={false}>
            <Datagrid>
                <TextField label="Last Name" source="last_name" />
                <TextField label="First Name" source="first_name" />
                <TextField label="Email" source="email" />
                <FunctionField label="Phone" render={ record => renderPhoneNumber(record.phone_cell) } />
                <NumberField label="Amount" source="donation_amount" locales="en-CA" options={{ style: 'currency', currency: 'CAD' }} />
                <TextField label="Fund Code" source="fund_code" />
                <TextField label="Source URL" source="source_url" />
                <BooleanField label="Monthly" source="donation_recurring" />
                <DateField label="Date" source="donation_datetime" showTime options={{ dateStyle: 'long', timeStyle: 'short' }} />
                <SendAutoresponseButton />
                <SendAutoresponsePreviewButton />
                <DownloadAutoresponseHTMLButton />
                <DownloadAutoresponsePDFButton />
            </Datagrid>
        </List>
    </div>
)

export default DonationsList;
