import * as React from "react";

import {
    List, Datagrid,
    TextField, FunctionField, DateField, ReferenceField,
    ReferenceInput, TextInput, AutocompleteInput,
    TopToolbar, FilterButton, ExportButton, sanitizeListRestProps
} from 'react-admin';

import renderPhoneNumber from './Renderers/renderPhoneNumber';

import SendAutoresponseButton from './SendAutoresponseButton';
import SendAutoresponsePreviewButton from './SendAutoresponsePreviewButton';

import DownloadAutoresponseHTMLButton from './DownloadAutoresponseHTMLButton';
import DownloadAutoresponsePDFButton from './DownloadAutoresponsePDFButton';

const SubmissionsListActions = (props) => {
    const {
        className,
        ...rest
    } = props;
    return ( <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <FilterButton />
        <ExportButton maxResults='100000' />
    </TopToolbar> );
};

const filters = [
    <ReferenceInput fullWidth label="Posts" source="post_id" reference="posts">
        <AutocompleteInput optionText="title" />
    </ReferenceInput>,
    <TextInput fullWidth label="Source URL" source="source_url" />,
    <TextInput fullWidth label="Email" source="email" />
];

export const SubmissionsList = (props) => (
    <List {...props} actions={<SubmissionsListActions {...props} />} filters={filters} sort={{ field: 'created_at', order: 'DESC' }} bulkActionButtons={false}>
        <Datagrid>
            <ReferenceField source="pattern_id" reference="patterns">
                <TextField source="label" />
            </ReferenceField>
            <ReferenceField source="post_id" reference="posts">
                <TextField source="title" />
            </ReferenceField>
            <TextField label="Last Name" source="values.last_name" />
            <TextField label="First Name" source="values.first_name" />
            <TextField label="Email" source="values.email" />
            <FunctionField label="Phone" render={ record => renderPhoneNumber(record.values.phone_cell) } />
            <DateField label="Date" source="created_at" showTime options={{ dateStyle: 'long', timeStyle: 'short' }} />
            <SendAutoresponseButton {...props} />
            <SendAutoresponsePreviewButton {...props} />
            <DownloadAutoresponseHTMLButton {...props} />
            <DownloadAutoresponsePDFButton {...props} />
        </Datagrid>
    </List>
)

export default SubmissionsList;
